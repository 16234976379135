import request from '@/utils/request2'

// 获取微信配置
export function getWxEntConfigInfo(data) {
  return request({
    url: '/api/m9908/getWxEntConfigInfo',
    method: 'get',
    params: data
  })
}

//获取当天的移动签到列表
export function getCurDateSignCardList(data) {
  return request({
    url: '/api/wx/getCurDateSignCardList',
    method: 'get',
    params: data
  })
}

// 获取员工移动签到允许的经纬度列表
export function getEmpSignPosition(data) {
  return request({
    url: '/api/wx/getEmpSignPosition',
    method: 'get',
    params: data
  })
}

// 获取员工高德地图key
export function getEmpMapKeyInfo(data) {
  return request({
    url: '/api/wx/getEmpMapKeyInfo',
    method: 'get',
    params: data
  })
}
// 点击签到前提醒按钮
export function beforeClickPhoneSignInfo(data) {
  return request({
    url: '/api/wx/beforeClickPhoneSignInfo',
    method: 'get',
    params: data
  })
}

// 保存移动签到前的提醒过程
export function beforePostPhoneSignInfo(data) {
  return request({
    url: '/api/wx/beforePostPhoneSignInfo',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}
// 保存移动签到信息
export function savePhoneSignInfo(data) {
  return request({
    url: '/api/wx/savePhoneSignInfo',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}
