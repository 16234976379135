<template>
  <div class="phonesign">
    <div v-show="loading" class="loading">
      <van-loading type="spinner" color="#1989fa" />
    </div>
    <van-nav-bar
      :title="'移动签到'"
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
      :right-text="wt ? '地点微调' : ''"
      @click-right="onClickRight"
    >
    </van-nav-bar>
    <div class="phonesignIndexBox">
      <div id="phonesign_container"></div>
      <div class="address">当前位置：{{ address }}</div>
      <div class="signlist">
        <div class="signbox" v-for="(item, index) in daylist" :key="index">
          <div class="signico"></div>
          <div>
            <div class="sigtime">
              打卡时间：<span>{{ item.hhmm }}</span>
            </div>
            <div class="sigtadd">
              {{ item.signaddr }}
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <!--  @click="b4clickSign" :before-read="b4clickSign(true)"-->
        <van-uploader
          v-if="needfile && !needdailog"
          v-model="fileList"
          max-count="1"
          ref="uploader"
          capture="camera"
          :accept="accept"
          :after-read="afterRead"
          :disabled="!cansign || !address"
        >
          <van-button
            :disabled="!cansign || !address"
            class="check"
            color="linear-gradient(to bottom, #23c1d1, #0a46ee)"
            size="small"
            round
          >
            考勤<br />签到
          </van-button>
        </van-uploader>
        <van-button
          v-else
          :disabled="!cansign || !address"
          class="check"
          color="linear-gradient(to bottom, #23c1d1, #0a46ee)"
          size="small"
          round
          @touchstart="cansign && address && b4clickSign(false)"
        >
          考勤<br />签到
        </van-button>
        <div class="cks2">你刚刚已签到{{ daylist.length }}次</div>
      </div>
    </div>
    <!-- 签到详细 -->
    <van-dialog
      :before-close="confirm"
      v-model="showdailog"
      title="签到详细"
      show-cancel-button
      class="signdetail"
    >
      <van-field
        v-model="content"
        rows="3"
        autosize
        required
        label="签到描述"
        type="textarea"
        placeholder="请输入签到描述"
      />
      <div class="btnlist" v-if="btnlist.length > 0">
        <van-button
          v-for="(item, index) in btnlist"
          :key="index"
          :type="item == choosbtn ? 'warning' : 'info'"
          @click="choosbtn = item"
          size="small"
          >{{ item }}</van-button
        >
      </div>
      <van-field name="uploader" label="文件上传">
        <template #input>
          <van-uploader
            v-model="fileList"
            max-count="1"
            capture="camera"
            :accept="accept"
          />
        </template>
      </van-field>
    </van-dialog>

    <van-popup v-model="showwt" round position="bottom">
      <van-picker
        show-toolbar
        :columns="pois"
        value-key="partname"
        :confirm-button-text="$t('module.confirm')"
        :cancel-button-text="$t('module.cancel')"
        @cancel="showwt = false"
        @confirm="onConfirmwt"
      />
    </van-popup>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { GetJsSign } from '@api/hp.js'
import { getDdSigurate } from '@api/dingding.js'
import {
  getWxEntConfigInfo,
  getCurDateSignCardList,
  getEmpSignPosition,
  beforeClickPhoneSignInfo,
  beforePostPhoneSignInfo,
  savePhoneSignInfo,
  getEmpMapKeyInfo
} from '@api/sign.js'
import { GetFilterDropDownList, upLoadFile } from '@api/wx.js'
import { Toast } from 'vant'
import { json } from 'body-parser'
import { parseTime, GetDistance } from '@/utils'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      map: null,
      marker: null,
      geocoder: null,
      center: [113.2932, 22.805412],
      address: '',
      daylist: [],
      loading: true,
      cansign: false, //是否允许点击我要签到
      needdailog: false, //是否需要显示详细内容
      showdailog: false,
      content: '',
      btnlist: [],
      choosbtn: '',
      needfile: true, //是否需要附件
      wt: true, //是否地点微调
      showwt: false, //显示地点微调列表
      posList: [],
      fileList: [],
      pois: [],
      accept: 'image/*', //video/* image/*
      sign_post_info: '', //签到成功提示语，如果未设置或为空表示无需弹窗提醒
      map_key: '25998b3462a186b782bb7cc9aa6c8383',
      map_secret: '8eb80839b1d45a2671066e915ad7d16a'
    }
  },
  mounted() {
    this._getEmpMapKeyInfo()
    this._getCurDateSignCardList()
    this._GetFilterDropDownList()
  },
  unmounted() {
    this.map?.destroy()
    this.marker?.destroy()
    this.geocoder?.destroy()
  },
  methods: {
    // 获取高德地图key
    _getEmpMapKeyInfo() {
      getEmpMapKeyInfo().then(res => {
        if (res.data.length > 0) {
          this.map_key = res.data[0].map_key
          this.map_secret = res.data[0].map_secret
        }
        this._getWxEntConfigInfo()
      })
    },
    // 获取签到参数配置
    _getWxEntConfigInfo() {
      getWxEntConfigInfo({ empid: this.userInfo.empid }).then(res => {
        // 微信
        if (res.data[0].sign_type == 0) this.weixinConfig()
        // 钉钉
        else if (res.data[0].sign_type == 1) this.dingdingConfig()
        // APP
        else if (res.data[0].sign_type == 2) {
          // this.center = [this.$route.query.lng, this.$route.query.lat]

          this.initAMap(res.data[0].sign_type)
          // this.initAMap(1)
        }
        // this.initAMap()
        this.needdailog = res.data[0].sign_must_select_flag
        this.needfile = res.data[0].sign_must_uploadfile
        this.wt = res.data[0].sign_can_adjust_position
        this.sign_post_info = res.data[0].sign_post_info
        this.accept = res.data[0].sign_filetype ? 'video/*' : 'image/*'
      })
    },
    // 获取当天签到记录
    _getCurDateSignCardList() {
      getCurDateSignCardList({ empid: this.userInfo.empid }).then(res => {
        res.data.forEach(e => {
          if (e.signaddr.indexOf('@_@') > -1)
            e.signaddr =
              e.signaddr.split('@_@')[0] +
              (e.signaddr.split('@_@')[1]
                ? '(' + e.signaddr.split('@_@')[1] + ')'
                : '')
        })
        this.daylist = res.data
      })
    },
    // 签到描述按钮
    _GetFilterDropDownList() {
      GetFilterDropDownList({
        moduleno: '610',
        username: this.userInfo.username,
        listsql: '@签到按钮描述'
      }).then(res => {
        if (res.data.length > 0) {
          this.btnlist = res.data.map(e => {
            return e.dicvalue
          })
        }
        // console.log(res)
      })
    },
    // 获取鉴权地址
    getmm() {
      let mm = window.location.href
      var u = navigator.userAgent
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isiOS) {
        mm = window.globalurls
      }
      if (mm.indexOf('#') > -1) mm = mm.split('#')[0]
      return mm
    },
    // 钉钉jssdk鉴权
    dingdingConfig() {
      getDdSigurate({
        url: encodeURIComponent(this.getmm()),
        entid: localStorage.mulEntid
      }).then(res => {
        if (window.dev) {
          alert(JSON.stringify(res))
        }
        const j = {
          appId: res.agentId, // 必填，微应用ID
          agentId: res.agentId, // 必填，微应用ID
          corpId: res.corpId, //必填，企业ID
          timeStamp: res.sTimeStamp, // 必填，生成签名的时间戳
          nonceStr: res.noncestr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名
          type: 0, //选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
          jsApiList: ['device.geolocation.get']
        }
        let _that = this
        dd.config(j)
        dd.ready(() => {
          if (window.dev) {
            alert('dd.ready')
          }
          dd.device.geolocation.get({
            targetAccuracy: 200,
            coordinate: 1,
            withReGeocode: true,
            useCache: true, //默认是true，如果需要频繁获取地理位置，请设置false
            onSuccess: result => {
              if (window.dev) {
                alert(JSON.stringify(result))
              }
              _that.center = [result.longitude, result.latitude]
              _that.initAMap()
              if (window.dev) {
                alert(_that.latitude)
                alert(_that.longitude)
              }
            },
            onFail: err => {
              if (window.dev) {
                alert(JSON.stringify(err))
              }
            }
          })
        })
        dd.error(error => {
          if (window.dev) {
            alert(
              JSON.stringify(error) + JSON.stringify(res) + window.globalurls
            )
          }
        })
      })
    },
    // 获取微信 jssdk并且开放 getLocation
    weixinConfig() {
      GetJsSign({
        param: this.getmm()
      }).then(res => {
        let _that = this
        wx.config({
          debug: false,
          appId: res.appId,
          timestamp: res.timestamp,
          nonceStr: res.nonceStr,
          signature: res.signature,
          jsApiList: ['getLocation']
        })

        wx.ready(function() {
          // 7.2 获取当前地理位
          wx.getLocation({
            type: 'gcj02',
            // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            cache: false,
            success: function(res) {
              _that.center = [res.longitude, res.latitude]
              _that.initAMap()
            },
            cancel: function(res) {
              alert('cancel' + JSON.stringify(res))
            }
          })
        })
        wx.error(function(w) {
          console.log('config error', w)
        })
      })
    },
    // 头部，右边按钮，显示微调
    onClickRight() {
      this.showwt = true
    },
    onClickLeft() {
      if (this.$route.query.from == 'home') {
        this.$router.push('/home')
      } else {
        this.$router.push('/personCen')
      }
    },
    // 确认微调
    onConfirmwt(value) {
      this.address = value
      this.showwt = false
    },
    // 初始化地图
    initAMap(sign_type) {
      window._AMapSecurityConfig = {
        securityJsCode: this.map_secret
      }
      AMapLoader.load({
        key: this.map_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Geocoder'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then(AMap => {
          this.map = new AMap.Map('phonesign_container', {
            // 设置地图容器id
            viewMode: '3D', // 是否为3D地图模式
            zoom: 15, // 初始化地图级别
            center: this.center // 初始化地图中心点位置
          })
          this.geocoder = new AMap.Geocoder({
            radius: 500, //以已知坐标为中心点，radius为半径，返回范围内兴趣点和道路信息
            extensions: 'all' //返回地址描述以及附近兴趣点和道路信息，默认“base”
          })
          if (sign_type == 2) {
            //参数说明：需要转换的坐标或者坐标组，需要转换的坐标类型，转换成功后的回调函数
            AMap.convertFrom(
              [this.$route.query.lng, this.$route.query.lat],
              'gps',
              (status, result) => {
                //status：complete 表示查询成功，no_data 为查询无结果，error 代表查询错误
                //查询成功时，result.locations 即为转换后的高德坐标系
                if (status === 'complete' && result.info === 'ok') {
                  var lnglats = result.locations //转换后的高德坐标 Array.<LngLat>
                  this.center = [lnglats[0].lng, lnglats[0].lat]
                  this.map.panTo(this.center)
                  this.setCenter(lnglats[0].lng, lnglats[0].lat)
                }
              }
            )
          }
          this._getEmpSignPosition()
        })
        .catch(e => {
          console.log(e)
        })
    },
    // 获取可以签到范围
    _getEmpSignPosition() {
      getEmpSignPosition({ empid: this.userInfo.empid }).then(res => {
        this.posList = res.data
        this.setCenter(this.center[0], this.center[1])
        this.posList.forEach(e => {
          // 构造矢量圆形
          var circle = new AMap.Circle({
            center: new AMap.LngLat(e.jd, e.wd), // 圆心位置
            radius: e.radius, //半径
            strokeColor: '#057de7', //线颜色
            strokeOpacity: 0.2, //线透明度
            strokeWeight: 2, //线粗细度
            fillColor: '#1791fc', //填充颜色
            fillOpacity: 0.35 //填充透明度
          })
          this.map.add(circle)
        })
      })
    },
    // 设置当前坐标
    setCenter(lng, lat) {
      if (this.marker) this.map.remove(this.marker)
      this.marker = new AMap.Marker({
        icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
        position: [lng, lat],
        anchor: 'bottom-center'
      })
      this.map.add(this.marker)
      this.cansign = false
      this.geocoder.getAddress([lng, lat], (status, result) => {
        if (status === 'complete' && result.regeocode) {
          this.loading = false
          this.address = result.regeocode.formattedAddress
          this.pois = [...result.regeocode.aois, ...result.regeocode.pois].map(
            e => {
              return e.name
            }
          )
          // 没有设置签到范围
          if (this.posList.length == 0) this.cansign = true
          else {
            // 设置签到范围,循环判断是否在范围内
            this.posList.forEach(e => {
              if (GetDistance(e.wd, e.jd, lat, lng) <= e.radius) {
                this.cansign = true
              }
            })
          }
        } else {
          this.address = ''
          this.cansign = false
          Toast('根据经纬度查询地址失败')
        }
      })
    },
    // 点击签到前提醒
    async b4clickSign(isuploader) {
      let next = true
      await beforeClickPhoneSignInfo({ empid: this.userInfo.empid }).then(
        async res => {
          if (res.data[0].isinfo && !res.data[0].cansave) {
            Notify({
              type: 'danger',
              message: res.data[0].info
            })
            next = false
          } else if (res.data[0].isinfo && res.data[0].cansave) {
            const a = await this.ask(res.data[0].info)
            if (a) {
              this.clickSign()
            } else {
              next = false
            }
          } else {
            this.clickSign()
          }
        }
      )
      return next
    },
    // 点击【我要签到】
    clickSign() {
      if (this.needdailog) {
        // 打开详细界面
        this.showdailog = true
      } else {
        // 直接提交
        this.b4PostSign()
      }
    },
    // 关闭签到详细
    confirm(action, done) {
      if (action == 'confirm') {
        if (this.needfile && this.fileList.length == 0) {
          Toast('请上传附件！')
          done(false)
          return
        }
        this.b4PostSign(done)
      } else {
        this.showdailog = false
        done()
      }
    },
    // 不显示详细界面时，触发附件上传
    choosefile() {
      if (this.fileList.length > 0) this.fileList = []
      this.$refs.uploader.chooseFile()
    },
    // 上传附件后
    afterRead() {
      console.log('afterRead')
      // 不显示详细界面时,直接提交
      this.b4PostSign(null)
    },
    // 提交前提醒
    b4PostSign(done) {
      this.PostSign(done)
      // 因为要兼容ios，所以beforePostPhoneSignInfo 接口作废
      // beforePostPhoneSignInfo({
      //   empid: this.userInfo.empid,
      //   signaddr: `${this.address}@_@${this.content +
      //     this.choosbtn}*_*${this.center.join(',')}*T*${parseTime(new Date())}`
      // }).then(async res => {
      //   if (res.data[0].isinfo && !res.data[0].cansave) {
      //     Notify({
      //       type: 'danger',
      //       message: res.data[0].info
      //     })
      //   } else if (res.data[0].isinfo && res.data[0].cansave) {
      //     const a = await this.ask(res.data[0].info)
      //     if (a) {
      //       this.PostSign(done)
      //     }
      //   } else {
      //     this.PostSign(done)
      //   }
      // })
    },
    // 提交签到
    PostSign(done) {
      const o = this.needfile
      this.needfile = false
      savePhoneSignInfo({
        empid: this.userInfo.empid,
        signaddr: `${this.address}@_@${this.content +
          this.choosbtn}*_*${this.center.join(',')}*T*${parseTime(new Date())}`
      }).then(res => {
        this._getCurDateSignCardList()
        if (this.fileList.length > 0) {
          upLoadFile({
            moduleno: '610',
            upflag: '2',
            autoid: res.data[0].autoid,
            uploadpath: 'AttachFile',
            username: this.userInfo.username,
            file: this.fileList[0].file
          }).then(res => {})
          // console.log(this.content, this.choosbtn, this.fileList)
        }

        if (done) done()
        if (this.sign_post_info) {
          Toast(this.sign_post_info)
        }
        this.fileList = []
        this.needfile = o
      })
    },
    ask(msg) {
      // element弹窗
      return new Promise((resolve, reject) => {
        // this.$confirm(msg, '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // })
        Dialog.confirm({
          title: '系统提示',
          message: msg
        })
          .then(() => {
            resolve(true)
          })
          .catch(() => {
            resolve(false)
          })
      })
    }
  }
}
</script>
<style scoped lang="less">
.phonesign {
  .loading {
    position: absolute;
    padding-top: 75%;
    padding-left: 45%;
    z-index: 1111;
  }
  .van-nav-bar {
    background: #2b8df0;
    z-index: 99;
    /deep/.van-nav-bar__left,
    /deep/.van-nav-bar__right {
      .van-icon,
      .van-nav-bar__text {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .phonesignIndexBox {
    margin-top: 1.22667rem;
    background: #fff;
    height: calc(100vh - 1.22667rem);
    #phonesign_container {
      width: 100vw;
      height: 20vh;
    }
    .address {
      padding: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 30px;
    }
    .signlist::-webkit-scrollbar {
      display: none;
    }
    .signlist {
      border-top: 5px solid #f0eff5;
      background: #fff;
      padding: 10px 30px 0;
      position: relative;
      overflow-y: scroll;
      height: 30vh;
      font-size: 20px;
      .signbox {
        border-left: 2px solid #f0eff5;
        padding-left: 10px;
        padding-bottom: 20px;
        display: flex;
        gap: 10px 10px;

        .signico {
          width: 4vw;
          height: 3.5vw;
          background-image: url('~@/assets/img/qrzq.png');
          background-size: 100% 100%;
        }
        .sigtadd {
          padding-left: 1em;
          background: url('~@/assets/img/sign.png') no-repeat;
          color: #5c5c5c;
          background-position-x: 0;
          background-position-y: 0.2em;
          background-size: 1em 1em;
        }
      }
    }
    .content {
      height: calc(100% - 50vh - 50px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .van-uploader {
        /deep/ .van-uploader__wrapper {
          .van-uploader__preview {
            display: none;
          }
        }
      }
      .check {
        font-size: 6vw;
        width: 20vw;
        height: 20vw;
      }
      .cks2 {
        font-size: 20px;
        padding-left: 1.2em;
        background: url('~@/assets/img/qrzq.png') left center no-repeat;
        background-size: 1em 1em;
        color: #e56e05;
      }
    }
  }
  .signdetail {
    .van-dialog__content {
      .btnlist {
        display: flex;
        gap: 10px;
        justify-content: space-around;
        padding: 0 10px;
        .van-button {
          flex: 1;
        }
      }
    }
  }
}
</style>
